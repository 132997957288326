import * as React from "react"
import axios from "axios"
import Layout from "../components/layout"
import Seo from "../components/seo"

/*
const packageId = "9426c94d-abdb-45fe-9a81-79be40d204d2"
axios.all([
  axios.get(`https://ckan0.cf.opendata.inter.prod-toronto.ca/api/3/action/package_show?id=${packageId}`),
])
  .then(axios.spread((responseMeetingAttendance) => {
    console.log('Response Meeting Attendance: ', responseMeetingAttendance)
  }))
*/

const CanadaTorontoPage = ({ pageContext }) => {
  return (
    <Layout>
      <Seo title="Canada" />
      <main className="l-main">
        <div className="l-constrained text-highlight">
          <h1>Canada / Toronto</h1>
        </div>
      </main>
    </Layout>
  )
}

export default CanadaTorontoPage
